import React from "react"
import { StaticQuery, graphql, useStaticQuery } from "gatsby"
import { Button, Row, Col, Card } from 'react-bootstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from '../components/footer'
import CommonFlex from './../components/commonFlex'
import Container from 'react-bootstrap/Container'
import { bdstatistics, apiURL, mqStatistics, GetQueryString, check } from '../components/census'
import Markdown from "react-markdown"

import './../assets/css/aritcle.css'
import './../assets/css/aritclesDetail.css'
import './../assets/css/market-cases.css'
import './../assets/css/bootstrap.min.css'


// 引入此路径，才不会打包失败
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import './../assets/css/navigation.min.css'
import './../assets/css/pagination.min.css'
import './../assets/css/scrollbar.min.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

let painSpots = [
    require('./../images/成本高@2x.png'),
    require('./../images/个性化需求多@2x.png'),
    require('./../images/联动@2x.png'),
    require('./../images/拓展@2x.png'),
    require('./../images/胸袭孤岛@2x.png'),
    require('./../images/智慧园区@2x.png')
]

let productCapabilitys = [
    require('./../images/整合@2x.png'),
    require('./../images/拓展性@2x.png'),
    require('./../images/集成@2x.png'),
    require('./../images/联动@211x.png'),
    require('./../images/整合@2x.png'),
    require('./../images/拓展性@2x.png'),
    require('./../images/集成@2x.png'),
    require('./../images/联动@211x.png'),
    require('./../images/整合@2x.png'),
    require('./../images/拓展性@2x.png'),
    require('./../images/集成@2x.png'),
    require('./../images/联动@211x.png'),
    require('./../images/整合@2x.png'),
    require('./../images/拓展性@2x.png'),
    require('./../images/集成@2x.png'),
    require('./../images/联动@211x.png'),
]


export const query = graphql`
query ApplicationScenarioQuery($id: Int!) {
  applicationScenario: strapiApplicationScenario(strapiId: {eq: $id}) {
    bannerTitle
    created_at
    homePage
    id
    introduction
    order
    updated_at
    strapiId
    sort
    published_at
    bannerImage {
      childImageSharp {
        fixed(width: 1600, height: 800) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    videoCase {
        publicURL
    }
    painSpots {
      desc
      id
      title
    }
    productCapabilitys {
      desc
      id
      title
    }
    productScenarios {
      id
      swipers {
        url
        id
      }
    }
    customerRevenueTitles {
      id
      desc
    }
  }
}
`

const ApplicationScenario = ({data}) => {
    const [ selected, setSelected ] = React.useState(false);
    const [ loading, setLoading ] = React.useState(false);
    const detailSwiperRef = React.useRef();

    const application = data.applicationScenario;
    application.platformUrl = null;  // 不预览平台
    console.log(application, '详情页面');

    return (
        <div className="aritcle-page articles-case">
            {
                !check() ? <div className="home-bg home-index articles-detail-bg" style={{
                    background: `url(${application?.bannerImage?.childImageSharp?.fixed?.src}) no-repeat`,
                    height: '34.375rem'
                }}>
                    <Layout>
                        <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
                        <div className="introduction-plat-jumbotron">
                            <div className="plat-title" style={{ paddingTop: '3.0625rem' }}>{application?.bannerTitle}</div>
                        </div>
                    </Layout>
                </div> : <div className="home-bg home-index articles-detail-bg" style={!application?.platformUrl ? {
                    background: `url(${application?.bannerImage?.childImageSharp?.fixed?.src}) no-repeat`,
                    height: '34.375rem'
                } : {}}>
                    <Layout noContainer={false} typePage={selected ? true : false}>
                        <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
                        {application?.platformUrl ? <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <div className={selected ? "lay-dbclick-box lay-svalue" : 'lay-dbclick-box lay-fullScreen'}>
                                <iframe id="caseIframe" src={application?.platformUrl} width="100%" height="100%" frameborder="0" allowfullscreen=""
                                    style={{
                                        position: 'absolute', left: 0, top: 0, zIndex: 88, marginTop: '4.5rem', width: '100%',
                                        height: selected ? '100%' : '37.5rem', border: 0, overflow: 'hidden'
                                    }}></iframe>

                                <div style={{ display: loading ? 'none' : 'block', position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', zIndex: 99 }}>
                                    <img style={{ width: '100%', height: '100%' }} src={`${application?.bannerImage?.childImageSharp?.fixed?.src}`} />
                                </div>
                                <div className="center-quick" style={{ display: loading ? 'none' : 'block' }}>案例加载中，也可点击右上角【全屏浏览】</div>
                            </div>
                            {selected ? null : <a target="_blank" href={application?.platformUrl} className="fullscreen">全屏浏览</a>}
                        </div> :
                            <div className="introduction-plat-jumbotron" style={{ marginTop: '3rem' }}>
                                <div className="plat-title" style={{ paddingTop: '16.0625rem' }}>{application?.bannerTitle}</div>
                            </div>}
                    </Layout>
                </div>
            }
            {
                selected ? null : <>
                    {
                        !check() ? null : application?.platformUrl ? <CommonFlex style={{ marginTop: 0 }} animate="animate1" title={application?.bannerTitle} desc=""></CommonFlex> : null
                    }
                    <Container>
                        <div className="introduction-yinyan" style={!check() ? { marginTop: '4rem' } : application?.platformUrl ? {} : { marginTop: '4rem' }}>
                            {/* <img src={require('./../images/“@2x.png')}></img> */}
                            <span>{application?.introduction}</span>
                        </div>
                        {
                            application?.videoCase?.publicURL? <>
                                <CommonFlex animate="animate1" title="案例讲解" desc=""></CommonFlex>
                                <video style={{ width: '100%', height: '100%' }} src={`${application?.videoCase?.publicURL}`} controls autoPlay="autoplay" muted={true} loop />
                            </> : null
                        }
                        <CommonFlex animate="animate1" title="行业痛点" desc=""></CommonFlex>
                        <Row xs={1} md={2} lg={2} className="row-painSpots">
                            {
                                application?.painSpots?.length ? application?.painSpots.map((item, index) => {
                                    return (
                                        <Col key={index}>
                                            <Card className="painSpots-card">
                                                <Card.Img variant="top" src={`${painSpots[index]} `} alt={item.title} />
                                                <Card.Body>
                                                    <Card.Title className="painSpots-title">{item.title}</Card.Title>
                                                    <div className="painSpots-desc">
                                                        <Markdown source={item.desc} escapeHtml={false} />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                }) : null
                            }
                        </Row>


                        <CommonFlex animate="animate1" title="产品能力" desc=""></CommonFlex>
                        <Row xs={1} md={2} lg={4} className="row-productCapabilitys">
                            {
                                application?.productCapabilitys?.length ? application?.productCapabilitys.map((item, index) => {
                                    return (
                                        <Col key={index}>
                                            <Card className="productCapabilitys-card">
                                                <div className="productCapabilitys-img-box">
                                                    <img variant="top" src={` ${productCapabilitys[index]} `} />
                                                </div>
                                                <Card.Body>
                                                    <Card.Title className="productCapabilitys-title">{item.title}</Card.Title>
                                                    <div className="productCapabilitys-desc">
                                                        <Markdown source={item.desc} escapeHtml={false} />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                }) : null
                            }
                        </Row>


                        <CommonFlex animate="animate1" title="应用场景" desc=""></CommonFlex>
                        <div className="articles-swiper">
                            {
                                application?.productScenarios?.[0]?.swipers?.length ? <Swiper
                                    style={{ width: '100%' }}
                                    className="articles-swipers"
                                    loop={application?.productScenarios?.[0]?.swipers?.length >= 3 ? true : false}
                                    autoplay={{ delay: 3000 }}
                                    ref={detailSwiperRef}
                                    modules={[Navigation, Autoplay, Pagination]}
                                    navigation
                                    slidesPerView={'auto'}
                                    centeredSlides={true}//居中
                                    loopedSlides={5}
                                    watchSlidesProgress={true}
                                    onSwiper={(swiper) => {
                                        swiper.$el[0].addEventListener('mouseover', function () { swiper.autoplay.stop() });
                                        swiper.$el[0].addEventListener('mouseleave', () => swiper.autoplay.start());
                                    }}
                                    onProgress={
                                        (progress) => {
                                            let slides = progress?.slides
                                            if (slides?.length >= 3) {
                                                for (let i = 0; i < slides.length; i++) {
                                                    let slide = slides.eq(i);
                                                    let slideProgress = slides[i].progress;
                                                    let modify = 1;
                                                    if (Math.abs(slideProgress) > 1) {
                                                        modify = (Math.abs(slideProgress) - 1) * 0.6 + 1;
                                                    }
                                                    let translate = slideProgress * modify * 260;
                                                    let scale = 1 - Math.abs(slideProgress) / 5;
                                                    let zIndex = 99 - Math.abs(Math.round(10 * slideProgress))
                                                    let x = translate
                                                    // if(translate == 0) x =  translate - 60 
                                                    // if(translate < 0) x =  translate - 100 
                                                    slide.transform('translateX(' + x + 'px) scale(' + scale + ')')
                                                    slide.css('zIndex', zIndex)
                                                    slide.css('opacity', 1)
                                                    // slide.css('width', '37.5rem')

                                                    if (Math.abs(slideProgress) >= 3) {
                                                        slide.css('opacity', 0)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    onSetTransition={
                                        transition => {
                                            let slides = transition?.slides
                                            if (slides?.length >= 3) {
                                                for (let i = 0; i < slides.length; i++) {
                                                    let slide = slides.eq(i)
                                                    slide.transition(transition);
                                                }
                                            }
                                        }
                                    }
                                >
                                    {
                                        application?.productScenarios?.[0]?.swipers?.length ? application?.productScenarios?.[0]?.swipers.map(item => {
                                            return (<SwiperSlide key={item?.id}>
                                                <img style={{ width: '100%' }} src={`/strapi${item.url}`} />
                                            </SwiperSlide>)
                                        }) : null
                                    }
                                </Swiper> : null
                            }
                        </div>

                        <CommonFlex animate="animate1" title="客户收益" desc=""></CommonFlex>
                    </Container>
                    <div className="customerRevenue">
                        <Container className="customerRevenue-container">
                            <img className="customerRevenue-img customerRevenue-img-left" src={require('./../images/编组4@2x.png')} />
                            <div className="customerRevenue-desc">{application?.customerRevenueTitles?.[0]?.desc}</div>
                            <img className="customerRevenue-img customerRevenue-img-right" src={require('./../images/编组2@2x.png')} />
                        </Container>
                    </div>
                    <Footer style={{ marginTop: '7.5rem' }}></Footer>
                </>
            }
        </div>
    )
}

export default ApplicationScenario